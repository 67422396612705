import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import './SectionContainer.scss';
import {GatsbyImage, getImage} from "gatsby-plugin-image";

export const SectionContainer = ({ bgColor, bgImage, className, children, id, top, width }) => {

    const classes = classNames(
        'section-container',
        className
    );

    const childClasses = classNames(
        'child-container',
        width !== 'full' && 'middle',
        width
    );

    const renderChildren = () => {
        return width !== 'full' ?  <div className={childClasses}  style={{width: width}}>{children}</div> : children
    }

    const renderBackgroundImage = () => {
        return bgImage && <div className='section-bg-image'><GatsbyImage alt={bgImage.title} image={getImage(bgImage)}/> </div>
    }


    return (
        <div className={classes} id={id} style={{backgroundColor: bgColor}}>
            {renderBackgroundImage()}
            {renderChildren()}
        </div>
    )
};

SectionContainer.defaultProps = {
    bgColor: '#F6F6F7',
    id: '',
    width: 'full',
};

SectionContainer.propTypes = {
    bgColor: PropTypes.string,
    bgImage: PropTypes.object,
    children: PropTypes.node,
    className: PropTypes.string,
    id: PropTypes.string,
    width: PropTypes.oneOf(['full', 'half', 'seventy']),
};

export default SectionContainer;