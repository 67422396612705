import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import './PanelGroup.scss'

export const PanelGroup = ({ children, className, divider, stretch, textAlign}) => {

    const classes = classNames(
        'panel-group',
        divider && 'panel-group-divided',
        stretch && 'stretch',
        `text-${textAlign}`,
        className
    );

    return (
        <div data-testid='panel-group' className={classes}>
            {children}
        </div>
    )
};

PanelGroup.defaultProps = {
    divider: false,
    stretch: false,
    textAlign: 'center'
};

PanelGroup.propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
    divider: PropTypes.bool,
    stretch: PropTypes.bool,
    textAlign: PropTypes.oneOf(['center', 'left', 'right']),
};

export default PanelGroup;