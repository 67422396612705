import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import './Pill.scss'

export const Pill = ({ className, children, color }) => {

    const classes = classNames(
        'pill',
        `pill-${color}`,
        className
    );

    return (
        <div className={classes}>
            {children}
        </div>
    )
};

Pill.defaultProps = {
    color: 'primary'
};

Pill.propTypes = {
    children: PropTypes.node,
    color: PropTypes.string,
    className: PropTypes.string,
};

export default Pill;