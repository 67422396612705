import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import './SimpleRow.scss'

export const SimpleRow = ({ bgColor, className, children, reverse }) => {

    const classes = classNames(
        'simple-row',
        reverse && 'reverse',
        className
    );

    return (
        <div className={classes}>
            <div className='content-section-1'>
                {children[0]}
            </div>
            <div className='content-section-2'>
                {children[1]}
            </div>
        </div>
    )
};

SimpleRow.defaultProps = {
    reverse: false
};

SimpleRow.propTypes = {
    children: PropTypes.node.isRequired,
    className: PropTypes.string,
    reverse: PropTypes.bool,
};

export default SimpleRow;