import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import { Button } from "../Button";
import { SectionContainer } from "../SectionContainer";
import { FooterColumn } from "./FooterColumn";
import { slugify, GatsbyLink } from "../../utils";
import { socialArray } from '../../data'
import Logo from './../../../assets/images/logos/rae-travel-logo.png';

import './Footer.scss'

export const Footer = ({className}) => {
    const classes = classNames(
        'footer',
        className
    );

    return (
        <SectionContainer className={classes}>
            <div className='column-row social'>
                {socialArray.map(({name, icon, link}) => (
                <div className='social-block' key={`social-icon-${slugify(name)}`}>
                    <GatsbyLink href={link} aria-label={`${name} Icon`}>{icon}</GatsbyLink>
                </div>
                ))}
            </div>
            <div className='column-row'>
                <FooterColumn className='contact' header=''>
                    <a className='button' style={{minWidth: '200px', padding: '20px'}} href='mailto:mallory@rae.travel?subject=Interested In An International Bachelorette'>
                        <div className='fancy-div' style={{lineHeight: '45px'}}>Start A Trip</div>
                    </a>
                </FooterColumn>
                <FooterColumn className='about' header=''>
                    <p>Our goal is to create a once-in-a-lifetime experience for your bachelorette trip that doesn't break the bank.</p>
                    <img
                        alt={'Rae Travels Logo'}
                        src={Logo}
                        className='footer-logo'
                        height={70}
                        width='auto'
                    />
                </FooterColumn>
                <FooterColumn className='subscribe' header=''>
                    <p>Join our community of women who receive the latest travel news, tips and advice.</p>

                    <div className="input-group">
                        <input type="text" className="form-control" placeholder="Email Address"/>
                        <span className="input-group-append">
                            <button type="button" className="btn btn-primary">Subscribe</button>
                        </span>
                    </div>
                </FooterColumn>
            </div>
            <div className='legal'>
               <ul>
                   <li>©{new Date().getFullYear()} Rae Travel. All rights reserved. Use of this site constitutes acceptance of our <GatsbyLink href='/terms-of-use' aria-label='Terms of Use'>Terms of Use</GatsbyLink> and <GatsbyLink href='/privacy-policy' aria-label='Privacy Policy'>Privacy Policy</GatsbyLink>.</li>
               </ul>
            </div>
        </SectionContainer>
    )
};

Footer.defaultProps = {

};

Footer.propTypes = {
    className: PropTypes.string,
};

export default Footer;