import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import NumberFormat from "react-number-format";

import {InputWrapper} from "../index";

import './NumberInput.scss'

export const NumberInput = (props) => {
    const {
        className,
        label,
        value,
        onBlur,
        key,
        defaultValue,
        disabled,
        decimalScale,
        displayType,
        required,
        id,
        name,
        format,
        prefix,
        suffix,
        decimalSeparator,
        thousandSeparator
    } = props;

    const classes = classNames(
        'rae-number',
        'rae-input form-control',
        className
    )

    return(
        <InputWrapper label={label} required={required} forId={id}>
            <NumberFormat
                className={classes}
                decimalScale={decimalScale}
                disabled={disabled}
                decimalSeparator={decimalSeparator}
                displayType={displayType}
                fixedDecimalScale={true}
                onBlur={onBlur}
                prefix={prefix}
                thousandSeparator={thousandSeparator}
                suffix={suffix}
                value={value}
                defaultValue={defaultValue}
                id={id}
                name={name}
                format={format}
                key={key}
            />
        </InputWrapper>
    )
}

NumberInput.defaultProps = {
    decimalScale: 0,
    displayType: 'input',
    thousandSeparator: ',',
    decimalSeparator: '.',
    required: false,
    disabled: false,
}

NumberInput.propTypes = {
    value:  PropTypes.number,
    className: PropTypes.string,
    currency: PropTypes.object,
    label: PropTypes.string,
    numberFormat: PropTypes.object,
    decimalScale: PropTypes.number,
    onBlur: PropTypes.func,
    disabled: PropTypes.bool,
    defaultValue: PropTypes.number,
    displayType: PropTypes.oneOf(['text', 'input']),
    required: PropTypes.bool,
}

export default NumberInput