import { useState, useEffect } from "react";

export const useElementHeight = (elementId) => {
    const [elementHeight, setElementHeight] = useState(0)

    useEffect(() => {
        handleScroll()

        function handleScroll() {
            const element = document.getElementById(elementId);
            const height = element.offsetHeight;
            setElementHeight(height)
        }

        window.addEventListener("resize", handleScroll);
        return () => window.removeEventListener('resize', handleScroll)
    }, [])

    return elementHeight
}


