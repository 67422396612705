import * as React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { useStaticQuery, graphql } from "gatsby";
import favicon from '../assets/images/favicon.jpeg';

function Seo({description, image, lang, meta, title }) {
    const { site } = useStaticQuery(
        graphql`
        query {
            site {
                siteMetadata {
                    title
                    description
                } 
            }
        }
        `,
    )

    const siteTitle = `${title} | ${site.siteMetadata.title}`
    const siteDescription = description || site.siteMetadata.description

    return (
        <Helmet
            htmlAttributes={{
                lang
            }}
            bodyAttributes={{
                class: 'preload',
                id: 'body'
            }}
        >
            <title>{siteTitle}</title>
            <meta name="description" content={siteDescription}/>

            <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no"/>

            <meta property="og:type" content="website" />
            <meta property="og:title" content={title}/>
            <meta property="og:description" content={siteDescription} />
            {/*<meta property="og:image" content="LINK TO THE IMAGE FILE" />*/}
            {/*<meta property="og:url" content="PERMALINK" />*/}
            <meta property="og:site_name" content={siteTitle} />
            <meta name="facebook-domain-verification" content="t77waah6w98dc4wkou8qndmbhr8tre" />

            <link rel="icon" href={favicon}/>
            <link rel="preconnect" href="//videos.ctfassets.net/q2qea63biivn/7GMfFfIvGIvjh0chkL2Wwq/8df4ee7884ca2809eb7e34082ad8b9b6/rae-travel-intro-video.mp4" />
            <link rel="preconnect" href="https://fonts.googleapis.com"/>
            <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin/>
            <link href="https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap" rel="stylesheet"/>

            {/*Start of HubSpot Embed Code*/}
            <script type="text/javascript" id="hs-script-loader" async defer src="//js-na1.hs-scripts.com/21301757.js"/>
            {/*End of HubSpot Embed Code*/}

            {/*Global site tag (gtag.js) - Google Analytics*/}
            <script async src="https://www.googletagmanager.com/gtag/js?id=G-GCG93Q29PD"/>
            <script>
                {`
                    window.dataLayer = window.dataLayer || [];
                    function gtag(){dataLayer.push(arguments);}
                    gtag('js', new Date());
    
                    gtag('config', 'G-GCG93Q29PD');
                `}
            </script>
            {/*End Google Analytics*/}

            {/*Facebook Pixel Code*/}
            <script>
                {`
                    !function(f,b,e,v,n,t,s)
                    {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
                        n.callMethod.apply(n,arguments):n.queue.push(arguments)};
                        if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
                        n.queue=[];t=b.createElement(e);t.async=!0;
                        t.src=v;s=b.getElementsByTagName(e)[0];
                        s.parentNode.insertBefore(t,s)}(window, document,'script',
                    'https://connect.facebook.net/en_US/fbevents.js');
                    fbq('init', '1606769152991845');
                    fbq('track', 'PageView');
                 `}
            </script>


            <noscript>
                {`
                <img height="1" width="1" style="display:none" src="https://www.facebook.com/tr?id=1606769152991845&ev=PageView&noscript=1"/>
                 `}
            </noscript>
            {/* End Facebook Pixel Code */}

            {/* Hotjar Tracking Code for https://www.rae.travel */}
            <script>
                {`
                    (function(h,o,t,j,a,r){
                    h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
                    h._hjSettings={hjid:2745884,hjsv:6};
                    a=o.getElementsByTagName('head')[0];
                    r=o.createElement('script');r.async=1;
                    r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
                    a.appendChild(r);
                    })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
                `}
            </script>
        </Helmet>
    )
}

Seo.defaultProps = {
    lang: 'en',
    meta: [],
}

Seo.propTypes = {
    title: PropTypes.string.isRequired,
    description: PropTypes.string,
    lang: PropTypes.string,
    meta: PropTypes.arrayOf(PropTypes.object),
};

export default Seo;