import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import Logo from './../../../../assets/images/logos/rae-travel-logo.png';

import './NavDesktop.scss'
import {GatsbyLink} from "../../../utils";

export const NavDesktop = ({ className }) => {

    const classes = classNames(
        'nav-desktop',
        className
    );

    return (
        <div className={classes}>
            <ul>
                <li className='link'><GatsbyLink href={'/'}>Home</GatsbyLink></li>
                <li className='link'><GatsbyLink href={'/blog'}>Blog</GatsbyLink></li>
                <li className='logo'> <GatsbyLink href={'/'}>
                    <img
                        alt='Rae Travels Logo'
                        src={Logo}
                    />
                </GatsbyLink></li>
                <li className='link'><GatsbyLink href={'/faq'}>FAQ</GatsbyLink></li>
                <li className='link'><GatsbyLink href={'/contact'}>Contact</GatsbyLink></li>
            </ul>
        </div>
    )
};

NavDesktop.defaultProps = {};

NavDesktop.propTypes = {
    className: PropTypes.string,
};

export default NavDesktop;