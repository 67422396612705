import React from 'react';
import PropTypes from 'prop-types';

import { FormGroup } from "../../../primitives";

import './InputWrapper.scss';
import classNames from "classnames";
import {GatsbyLink} from "../../../utils";

export const InputWrapper = (props) => {
    const {
        children,
        formClass,
        forId,
        helperText,
        label,
        secondaryLabel,
        secondaryLink,
        secondaryTabIndex,
        showSecondaryMessage,
        secondaryMessage
    } = props;


    const classes = classNames(
        'input-wrapper',
        formClass
    )

    const renderLabel = () => {
        return label !== undefined &&   <label className="rae-input-primary-label" htmlFor={forId}>{label} {renderSecondaryLabel()}</label>
    }

    const renderSecondaryLabel = () => {
        return secondaryLabel &&  <span className="pull-right"><GatsbyLink href={(secondaryLink ? (secondaryLink) : ("") )} className="secondary-label small" tabIndex={secondaryTabIndex}>{secondaryLabel}</GatsbyLink></span>
    }

    const renderHelperText = () => {
        return helperText &&  <p className="helper-text">{helperText}</p>
    }

    const renderSecondaryMessage = () => {
        return showSecondaryMessage &&  <p className="secondary-text">{secondaryMessage}</p>
    }

    return (
        <FormGroup className={classes}>
            {renderLabel()}
            {renderSecondaryMessage()}
            {children}
            {renderHelperText()}
        </FormGroup>
    )
}

InputWrapper.propTypes = {
    children: PropTypes.node,
    formClass: PropTypes.string,
    forId: PropTypes.string,
    helperText: PropTypes.string,
    label: PropTypes.string,
    secondaryLabel: PropTypes.string,
    secondaryLink: PropTypes.string,
    secondaryTabIndex: PropTypes.number,
    secondaryMessage: PropTypes.string,
    showSecondaryMessage: PropTypes.bool
}

InputWrapper.defaultProps = {
    formClass: '',
    showSecondaryMessage: false
}

export default InputWrapper