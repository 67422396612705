    import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import './FooterColumn.scss'

export const FooterColumn = ({ children, className, header }) => {

    const classes = classNames(
        'footer-column',
        className
    );

    const renderHeader = () => {
        return header && <h3 className='header'>{header}</h3>
    }

    return (
        <div className={classes}>
            {renderHeader()}
            <div className='content'>
                {children}
            </div>
        </div>
    )
};

FooterColumn.propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
    header: PropTypes.string,
};

export default FooterColumn;