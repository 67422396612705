import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { GatsbyImage, getImage } from "gatsby-plugin-image"

import './Hero.scss'
import PaintBanner from "../../../assets/svg/brush-stroke-banner.inline.svg";

export const Hero = ({ className, image, title }) => {

    const classes = classNames(
        'hero',
        className
    );

    return (
        <div className={classes}>
            <GatsbyImage className='hero-image' alt={title} image={getImage(image)}/>
            <div className='content'>
                <PaintBanner className='paint-banner'/>
                <h1 className='title'>{title}</h1>
            </div>
        </div>
    )
};

Hero.defaultProps = {
    bgColor: 'transparent'
};

Hero.propTypes = {
    image: PropTypes.object.isRequired,
    className: PropTypes.string,
    header: PropTypes.string,
    title: PropTypes.string
};

export default Hero;