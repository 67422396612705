import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import {slugify, useMobileBool, GatsbyLink} from "../../../utils";
import {socialArray} from "../../../data";

import Logo from './../../../../assets/images/logos/rae-travel-logo.png';
import MenuIcon from '../../../../assets/svg/bars-solid.inline.svg'
import CloseIcon from '../../../../assets/svg/times-solid.inline.svg'

import './NavMobile.scss'

export const NavMobile = ({ className, showSideBar, setShowSideBar }) => {

    const classes = classNames(
        'nav-mobile',
        className
    );

    const isMobile = useMobileBool();

    const renderMobileBtn = () => {
        return isMobile &&  <div className='btn-box'>
            <button aria-label='Open Menu' onClick={() => setShowSideBar(!showSideBar)}>
                <MenuIcon className='nav-icon'/>
            </button>
        </div>
    }

    const renderSideBar = () => {
        return showSideBar && <div className='mobile-side-nav'>
            <div className='btn-box'>
                <button onClick={() => setShowSideBar(!showSideBar)}>
                    <CloseIcon  className='nav-icon'/>
                </button>
            </div>
            <ul>
                <li className='link'><GatsbyLink href={'/'}>Home</GatsbyLink></li>
                <li className='link'><GatsbyLink href={'/blog'}>Blog</GatsbyLink></li>
                <li className='link'><GatsbyLink href={'/faq'}>FAQ</GatsbyLink></li>
                <li className='link'><GatsbyLink href={'/contact'}>Contact</GatsbyLink></li>
            </ul>
            <div className='social-row'>
                {socialArray.map(({name, icon, link}) => (
                    <div className='social-block' key={`social-icon-${slugify(name)}`}>
                        <GatsbyLink href={link} aria-label={`${name} Icon`}>{icon}</GatsbyLink>
                    </div>
                ))}
            </div>
        </div>
    }

    return (
        <>
            <div className={classes}>
                {renderMobileBtn()}
                <GatsbyLink className='nav-logo' href={'/'}>
                    <img
                        alt='Rae Travels Logo'
                        src={Logo}
                        className='nav-logo'
                        height={125}
                    />
                </GatsbyLink>
            </div>
            {renderSideBar()}
        </>
    )
};

NavMobile.defaultProps = {
    showSideBar: false,
};

NavMobile.propTypes = {
    showSideBar: PropTypes.bool.isRequired,
    setShowSideBar: PropTypes.func.isRequired,
    className: PropTypes.string,
};

export default NavMobile;