import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import {InputWrapper} from "./InputWrapper";

import './Input.scss'



export const Input = (props) => {
    const {
        className,
        defaultValue,
        disabled,
        formClass,
        id,
        helperText,
        label,
        name,
        onBlur,
        onKeyUp,
        placeholder,
        required,
        secondaryLabel,
        secondaryLink,
        secondaryTabIndex,
        show,
        tabIndex,
        type,
    } = props;

    const classes = classNames(
        'rae-input',
        helperText && 'has-helper-text',
        'w-100',
        className
    )

    const cleanId = id === '' ? name : id;

    const renderPlaceHolder = () => {
        return placeholder ? placeholder : label;
    }

    return (
      <>
          {show &&
          <InputWrapper formClass={formClass} helperText={helperText} label={label} required={required} secondaryLabel={secondaryLabel} secondaryLink={secondaryLink} secondaryTabIndex={secondaryTabIndex} forId={cleanId}>
              <input
                type={type}
                id={cleanId}
                disabled={disabled}
                className={classes}
                name={name}
                placeholder={renderPlaceHolder()}
                onKeyUp={onKeyUp}
                onBlur={onBlur}
                defaultValue={defaultValue}
                required={required}
                tabIndex={tabIndex}
              />
          </InputWrapper>
          }
      </>
    )
}

Input.propTypes = {
    disabled: PropTypes.bool.isRequired,
    name: PropTypes.string.isRequired,
    id: PropTypes.string,
    label: PropTypes.string,
    required: PropTypes.bool,
    type: PropTypes.string,
    defaultValue: PropTypes.string,
    formClass: PropTypes.string,
    helperText: PropTypes.string,
    onBlur: PropTypes.func,
    onKeyUp: PropTypes.func,
    placeholder: PropTypes.string,
    secondaryLabel: PropTypes.string,
    secondaryLink: PropTypes.string,
    secondaryTabIndex: PropTypes.number,
    show: PropTypes.bool,
    tabIndex: PropTypes.number,
}

Input.defaultProps = {
    id: '',
    type: 'text',
    disabled: false,
    required: false,
    show: true
}

export default Input