import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Datetime from "react-datetime";

import { FormGroup } from "../../primitives";
// import { isValidDate } from "./isValidDate";

import './DateTimeInput.scss'

export const DateTimeInput = (props) => {
    const {
        className,
        closeOnSelect,
        dateFormat,
        defaultValue,
        disabled,
        id,
        label,
        name,
        onBlur,
        onChange,
        placeholder,
        readOnly,
        required,
        timeFormat,
        isValidDate
    } = props;

    const classes = classNames(
        'rae-date-time-input',
        'standard-input',
        className
    )

    const renderLabel = () => {
        return label !== undefined &&   <label className="rae-input-primary-label" htmlFor={id}>{label}</label>
    }

    return (
        <FormGroup className={'rae-date-time'}>
            {renderLabel()}
            <Datetime
                isValidDate={isValidDate}
                initialValue={defaultValue}
                dateFormat={dateFormat}
                timeFormat={timeFormat}
                closeOnSelect={closeOnSelect}
                readOnly={readOnly}
                onChange={onChange}
                inputProps={{ className: classes, name: name, placeholder: placeholder, disabled: disabled, required: required, readOnly: readOnly, id: id, onBlur: onBlur}}
            />
        </FormGroup>
    )
}

DateTimeInput.defaultProps = {
    closeOnSelect: true,
    readOnly: true,
    required: false,
    timeFormat: ''
}

DateTimeInput.propTypes = {
    className: PropTypes.string,
    closeOnSelect: PropTypes.bool,
    dateFormat: PropTypes.string,
    defaultValue: PropTypes.instanceOf(Date),
    disabled: PropTypes.bool,
    id: PropTypes.string,
    label: PropTypes.string,
    name: PropTypes.string,
    onBlur: PropTypes.func,
    onChange: PropTypes.func,
    placeholder: PropTypes.string,
    readOnly: PropTypes.bool,
    required: PropTypes.bool,
    timeFormat: PropTypes.string,
}

export default DateTimeInput;