import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import {InputWrapper} from "../Input/InputWrapper";

import './RadioButtons.scss'
import {slugify} from "../../utils";

export const RadioButtons = (props) => {
    const {
        className,
        helperText,
        label,
        required,
        secondaryLabel,
        secondaryLink,
        secondaryTabIndex,
        show,
        optionArray
    } = props;

    const fromClasses = classNames(
        'radio-button-group',
        className
    )

    return (
        <>
            {show &&
            <InputWrapper formClass={fromClasses} helperText={helperText} label={label} required={required} secondaryLabel={secondaryLabel} secondaryLink={secondaryLink} secondaryTabIndex={secondaryTabIndex}>
                <div className='shrinker'>
                {optionArray.map( ({groupName, id, value}) => (
                    <label key={`${slugify(id)}-radio-button`} className='radio' htmlFor={id}>
                        <input className='radio-input' type="radio" id={id} name={groupName} value={value}/>
                        {value}
                    </label>
                ))}
                </div>
            </InputWrapper>
            }
        </>
    )
}

RadioButtons.propTypes = {
    optionArray: PropTypes.array.isRequired,
    className: PropTypes.string,
    label: PropTypes.string,
    required: PropTypes.bool,
    helperText: PropTypes.string,
    secondaryLabel: PropTypes.string,
    secondaryLink: PropTypes.string,
    secondaryTabIndex: PropTypes.number,
    show: PropTypes.bool,
}

RadioButtons.defaultProps = {
    optionArray: [],
    disabled: false,
    required: false,
    show: true
}

export default RadioButtons