import React from "react";
import { Link } from "gatsby";

export const isExternalLink = (url) => {
    try {
        return new URL(url).origin !== window.location.origin;
    } catch (err) {
        return false
    }
}

export const GatsbyLink = (props) => {
    const { href, children } = props;

    if(isExternalLink(href)) {
        return (
            <a href={href} {...props}>
                {children}
            </a>
        )
    } else {
        return <Link to={href} {...props}>
            {children}
        </Link>
    }
}

