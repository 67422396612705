import React from "react";
import {slugify} from "./index";

import OtherIcon from '../../assets/images/question-mark.png';

export const shapeCheckboxData = (data, type, groupName, includeOther) => {

    data = !includeOther ? data : data.concat([{name: 'Other', icon: {fluid: {src: getOtherIcon(type)}}}])

    if(type === 'topCategories') {
        return data.map( ({node}) => (
            {value: node?.name ?? '', groupName: groupName, id: slugify(node?.name ?? ''), icon: node?.icon ?? ''}
        ))
    } else if (type === 'whereToGo') {
        return data.map( ({icon, name}) => (
            {value: name, groupName: groupName, id: slugify(name), icon: icon.fluid}
        ))
    }
}

const getOtherIcon = (type) => {
    switch(type) {
        case 'topCategories':
            return OtherIcon
        case 'whereToGo':
            return OtherIcon
    }
}
