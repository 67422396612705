import React, {useState} from 'react';
import PropTypes from 'prop-types';

import { NavMobile } from "./NavMobile";
import { NavDesktop } from "./NavDesktop";
import { useMobileBool } from "../../utils";

import './NavBar.scss'


export const NavBar = ({ className }) => {

    const isMobile = useMobileBool();
    const [showSideBar, setShowSideBar] = useState(false)


    return (
       isMobile ?
                <NavMobile
                    showSideBar={showSideBar}
                    setShowSideBar={setShowSideBar}
                /> : <NavDesktop/>
    )
};

NavBar.defaultProps = {};

NavBar.propTypes = {
    className: PropTypes.string,
};

export default NavBar;