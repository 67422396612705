import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import './Panel.scss'
import {GatsbyImage} from "gatsby-plugin-image";

export const Panel = ({ className, description, icon, title, titleTag: TitleTag }) => {

    const classes = classNames(
        'panel',
        className
    );

    const renderDescription = () =>
        description && <p className='panel-description'>{description}</p>

    const renderIcon = () =>
        icon && <GatsbyImage className='panel-icon' imgClassName='panel-icon' image={icon} alt={title}/>

    const renderTitle = () =>
        title && <TitleTag className='panel-title'>{title}</TitleTag>

    return (
        <div className={classes}>
            {renderIcon()}
            {renderTitle()}
            {renderDescription()}
        </div>
    )
};

Panel.defaultProps = {
    titleTag: 'h3'
};

Panel.propTypes = {
    className: PropTypes.string,
    description: PropTypes.string,
    icon: PropTypes.object,
    title: PropTypes.string,
    titleTag: PropTypes.string
};

export default Panel;