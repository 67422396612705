import React, {useEffect} from 'react'
import Helmet from 'react-helmet'
import PropTypes from 'prop-types';
import CookieConsent from "react-cookie-consent";

import { Footer, NavBar } from "./primitives";

import './layout.scss'
import {useMobileBool} from "./utils";
import classNames from "classnames";

const Layout = ({children, className}) => {

    useEffect(() => {
        setTimeout(() => {
            const element = document.getElementById('body');
            element.classList.remove('preload');
        }, 500);
    }, []);

    const isMobile = useMobileBool();

    const classes = classNames(
        isMobile && 'mobile-main',
        className
    )

  return (
      <>
        <Helmet/>
        <NavBar />
        <main className={classes}>{children}</main>
        <Footer/>
          {/*<CookieConsent*/}
          {/*    enableDeclineButton*/}
          {/*    location="bottom"*/}
          {/*    buttonText="Accept"*/}
          {/*    declineButtonText="Decline"*/}
          {/*    style={{ background: "#F6F6F7" }}*/}
          {/*    buttonStyle={{ background: "#252324", color: "white"}}*/}
          {/*    declineButtonStyle={{ background: "white", color: "#252324"}}*/}
          {/*    cookieName="gatsby-gdpr-google-analytics">*/}
          {/*    <span style={{ color: "#252324" }}>This site uses cookies...</span>*/}
          {/*</CookieConsent>*/}
      </>
  )
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string
};

export default Layout
