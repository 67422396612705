import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import './FormGroup.scss'


export const FormGroup = (props) => {
    const {
        children,
        className,
        label,
        required,
    } = props;

    const classes = classNames(
        'rae-form-group',
        className
    )

    const renderLabel = () => {
        return label !== undefined &&  <label className="rae-input-primary-label">
            {label}
            {required && <span className="color-red">*</span>}
        </label>
    }

    return (
        <div className={classes}>
            {renderLabel()}
            {children}
        </div>
    )
}

FormGroup.defaultProps = {
    required: false,
}

FormGroup.propTypes = {
    children: PropTypes.node.isRequired,
    className: PropTypes.string,
    label: PropTypes.string,
    required: PropTypes.bool,
}

export default FormGroup;