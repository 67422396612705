import React, {Fragment} from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import {GatsbyLink} from "../../utils";

import './Breadcrumb.scss'


export const Breadcrumb = ({ location }) => {

    const classes = classNames(
        'breadcrumbs',
    );

    const unslugify = (string) => {
        const search = '-'
        const replacer = new RegExp(search, 'g')
        return string.replace(replacer, ' ')
    }

    const createCrumb = (name, pathname) => {
        const link = pathname.substring(0, pathname.indexOf(name)) + name + '/';
        const displayName = unslugify(name)

        return (
            <Fragment>
                <GatsbyLink href={link} className='breadcrumb-name'>
                {displayName}
                </GatsbyLink>
                <div className='breadcrumb-divider'>/</div>
            </Fragment>
        )
    }

    const renderCrumbs = () => {
        const { pathname } = location;
        const crumbNames = pathname.split('/').filter((i) => i);
        return crumbNames.map((name) => createCrumb(name, pathname))
    }

    return (
        <div className={classes}>
            {renderCrumbs()}
        </div>
    )


};

Breadcrumb.defaultProps = {

};

Breadcrumb.propTypes = {
    className: PropTypes.string,
};

export default Breadcrumb;