import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import {GatsbyLink} from "../../utils";

import './Button.scss'


export const Button = ({ className, fancy, href, label, isButton, type}) => {

    const classes = classNames(
        'button',
        fancy && 'fancy',
        className
    );

    const renderFancy= () => {
        return fancy && <div className='fancy-div'/>
    }

    if(isButton) {
        return (
            <button className={classes} type={type}>
                {label}
                {renderFancy()}
            </button>
        )
    } else {
        return (
            <GatsbyLink className={classes} href={href}>
                {label}
                {renderFancy()}
            </GatsbyLink>
        )
    }

};

Button.defaultProps = {
    fancy: true,
    isButton: false,
    type: 'button'
};

Button.propTypes = {
    label: PropTypes.string.isRequired,
    className: PropTypes.string,
    fancy: PropTypes.bool,
    href: PropTypes.string,
    isButton: PropTypes.bool,
    type: PropTypes.string,
};

export default Button;